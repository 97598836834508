// import Lang from "./Lang";
// import { useTranslation } from "react-i18next";
import { HeaderSection, ImageLogo, /*LinkContact,*/ NavBar } from "../Styles/NavBar";

export function Header(mailto: string) {
    // const { t } = useTranslation();

    window.onscroll = function () {
        if (document.getElementById("NAVBAR")) {
            if (window.scrollY + document.getElementById("NAVBAR")!.getBoundingClientRect().top > (window.innerWidth > 600 ? 120 : 70)) {
                document.getElementById("NAVBAR")!.style.backgroundColor = "white"
            } else {
                document.getElementById("NAVBAR")!.style.backgroundColor = ""
            }
        }
    }

    return (
        <NavBar id={"NAVBAR"}>
            <HeaderSection /* style={{ backgroundImage: `url(${DemiCercle})`,  backgroundPosition: 'top', backgroundSize: '100%', backgroundRepeat: 'no-repeat'}}*/>
               {/* <Lang /> */}
              
                <ImageLogo />
                 {/* <LinkContact href={mailto} >{t('footer.titreContact')}</LinkContact>  */}
            </HeaderSection>
        </NavBar>
    )
}
export default Header