//import { Ul, Li, Col25 } from "../Styles/Footer"
//import { Centrer, StyledLink } from "../Styles/NavBar";
// import { useTranslation } from "react-i18next";
import { BoxBlue } from "../Styles/Global"
import { logoImAAAgery, widthLogo, altLogo } from "../i18n/Images/LogoImaaagery"
import { Centrer } from "../Styles/NavBar";
// import { LinkContact } from "../Styles/NavBar";
export function Footer(mailto: string) {
    // const { t } = useTranslation();
    return (
        <BoxBlue>
             <Centrer style={{flexDirection: 'column'}}>
                <img style={{objectFit: 'contain'}} src={logoImAAAgery} width={widthLogo} alt={altLogo}></img>
                <br/>
                <div style={{fontSize:"0.5em",color:"darkgray"}}>S.A.S IMAAAGERY</div>
                {/* <p>
                    <LinkContact href={mailto} >{t('footer.titreContact')}</LinkContact>
                </p> */}
            </Centrer>
        </BoxBlue>
    )
}

export default Footer