import imgAlain from "../../Assets/QuiSommesNous/PortraitAlainM.png"
import imgKarine from "../../Assets/QuiSommesNous/PortraitAlainM.png"
import imgLeo from "../../Assets/QuiSommesNous/PortraitAlainM.png"
import imgPartenaire from "../../Assets/QuiSommesNous/PortraitAlainM.png"

export const portraitAlain = imgAlain
export const altPortraitAlain = "Portrait Alain M"
export const widthPortraitAlain = 300

export const portraitKarine = imgKarine
export const altPortraitKarine = "Portrait Karine L"
export const widthPortraitKarine = 300

export const portraitLeo = imgLeo
export const altPortraitLeo = "Portrait Leo D"
export const widthPortraitLeo = 300

export const portraitPartenaire = imgPartenaire
export const altPortraitPartenaire = "Portrait Partenaire"
export const widthPortraitPartenaire = 300