import { useSelector, useDispatch } from 'react-redux';
import CircularProgressbar from './CircularProgressBar';

import { Circle } from '../Styles/Instagram';
import { useEffect, useState } from 'react';

export function isInViewport(element: any) {
	const rect = element.getBoundingClientRect();
	return (
		rect.top >= 0 &&
		rect.left >= 0 &&
		rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
		rect.right <= (window.innerWidth || document.documentElement.clientWidth)
	);
}

interface StepProps {
	key: number;
	id: number;
	index: number;
	label: string;
	updateTexte: (index: number) => void;
	selected: boolean;
}

export default function Step(props: StepProps) {
	const [ready, setReady] = useState(false);
	const [isInViewportState, setIsInViewportState] = useState(false);
	const animationValues = useSelector((state: any) => state.stepNavAnimations['idStep' + props.id]);
	const dispatch = useDispatch();
	const [animationValue, setAnimationValue] = useState(0);
	const [animationFinished, setAnimationFinished] = useState(false);
	const animationPaused = useSelector((state: any) => state.animationPaused);

	useEffect(() => {
		setTimeout(() => {
			if (animationValue <= 100 && ready && !animationPaused) {
				setAnimationValue(animationValue + 0.5);
				dispatch({
					type: 'UPDATE_ANIMATION_VALUE',
					value: animationValue,
					idStep: props.id,
					idAnim: props.index,
				});
			}
		}, 20);
		//eslint-disable-next-line
	}, [animationValue, ready, animationPaused]);

	useEffect(() => {
		if (isInViewportState) {
			if (props.index === 0 && animationValues.idAnim0 !== 100) {
				setReady(true);
			}

			if (props.index === 1 && animationValues.idAnim0 === 100) {
				setReady(true);
			}
			if (props.index === 2 && animationValues.idAnim1 === 100) {
				setReady(true);
			}
			if (animationValues.idAnim2 === 100) {
				setAnimationFinished(true);
			}
		}
	}, [isInViewportState, props.index, animationValues]);

	useEffect(() => {
		window.addEventListener('scroll', () => {
			if (document.getElementById('ASK' + props.id)) {
				if (isInViewport(document.getElementById('ASK' + props.id))) {
					setIsInViewportState(true);
				}
			}
		});
	}, [props.id]);

	useEffect(() => {
		if (animationPaused) {
			setTimeout(() => {
				dispatch({
					type: 'RESUME_ANIMATION',
				});
			}, 4000);
		}
		//eslint-disable-next-line
	}, [animationPaused]);

	useEffect(() => {
		if (animationFinished) {
			setReady(false);
			setTimeout(() => {
				dispatch({
					type: 'RESET_ANIMATION_VALUES',
					idStep: props.id,
				});
				setAnimationValue(0);
				setAnimationFinished(false);
			}, 6000);
		}
		//eslint-disable-next-line
	}, [animationFinished]);

	useEffect(() => {
		updateTextAndTitle();
		//eslint-disable-next-line
	}, [ready]);

	const clickHandler = () => {
		updateTextAndTitle();
		!animationFinished && dispatch({ type: 'PAUSE_ANIMATION' });
	};

	const updateTextAndTitle = () => {
		props.updateTexte(props.index);
		//props.id represente le 1er instagram
		switch (props.index) {
			case 0:
				let askTitle = document.getElementById('ASK' + props.id);
				if(askTitle){
					askTitle.style.fontSize = '40px';
				}

				let analyseTitle =  document.getElementById('ANALYSE' + props.id)
				if(analyseTitle){
					analyseTitle.style.fontSize = '28px';
				}

				let answerTitle =  document.getElementById('ANSWER' + props.id)
				if(answerTitle){
					answerTitle.style.fontSize = '28px';
				}
				break;

			case 1:
				let askTitle1 = document.getElementById('ASK' + props.id);
				if (askTitle1) {
					askTitle1.style.fontSize = '28px';
				}

				let analyseTitle1 = document.getElementById('ANALYSE' + props.id);
				if (analyseTitle1) {
					analyseTitle1.style.fontSize = '40px';
				}

				let answerTitle1 = document.getElementById('ANSWER' + props.id);
				if (answerTitle1) {
					answerTitle1.style.fontSize = '28px';
				}
				break;

			case 2:
				let askTitle2 = document.getElementById('ASK' + props.id);
				if (askTitle2) {
					askTitle2.style.fontSize = '28px';
				}

				let analyseTitle2 = document.getElementById('ANALYSE' + props.id);
				if (analyseTitle2) {
					analyseTitle2.style.fontSize = '28px';
				}

				let answerTitle2 = document.getElementById('ANSWER' + props.id);
				if (answerTitle2) {
					answerTitle2.style.fontSize = '40px';
				}
				break;
			default:
				break;
		}
	};

	return (
		<Circle onClick={() => clickHandler()}>
			<CircularProgressbar
				strokeWidth='4'
				size={window.innerWidth < 2000 ? 70 : 85}
				percentage={animationValue}
			/>
		</Circle>
	);
}
