import { DivImage } from '../Styles/RondImage'

export function RondImage(img: any, alt: any, width: number) {
    return (
        <DivImage>
            <img  style={{objectFit: 'cover'}} src={img} alt={alt} height="auto" width="100%"></img>
        </DivImage>
    )
}

export default RondImage