import Step from "./Step.tsx";
import { StepWrapper } from "../Styles/Instagram.tsx";

export default function StepNavigation(props) {
    return (
        <StepWrapper >
            {props.labelArray.map((item, index) => <Step key={index} id={props.id} index={index} label={item} updateTexte={props.updateTexte} selected={props.currentTexte === index + 1}></Step>)}
        </StepWrapper>
    )
}
