const initState = {
    stepNavAnimations: {
        idStep1: {
            idAnim0: 0,
            idAnim1: 0,
            idAnim2: 0
        },
        idStep2: {
            idAnim0: 0,
            idAnim1: 0,
            idAnim2: 0
        },
        idStep3: {
            idAnim0: 0,
            idAnim1: 0,
            idAnim2: 0
        }
    },
    animationPaused: false
}

const Reducer = (state=initState, action) => {
    switch(action.type){
        case 'UPDATE_ANIMATION_VALUE':
            return{
                ...state,
                stepNavAnimations: { ...state.stepNavAnimations, ['idStep' + action.idStep]: { ...state.stepNavAnimations['idStep' + action.idStep], ['idAnim' + action.idAnim] : action.value}}
            }

        case 'RESET_ANIMATION_VALUES':
            return{
                ...state,
                stepNavAnimations: { ...state.stepNavAnimations, ['idStep' + action.idStep]: {
                    idAnim0: 0,
                    idAnim1: 0,
                    idAnim2: 0
                }}
            }
        case 'PAUSE_ANIMATION':
            return{
                ...state,
                animationPaused: true
            }
        case 'RESUME_ANIMATION':
            return{
                ...state,
                animationPaused: false
            }
        default:
            return state
    }
}

export default Reducer