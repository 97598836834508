import styled from 'styled-components';

export const DivInsta = styled.div`
	display: flex;
	flex-wrap: nowrap;
	flex-basis: 100%;
	margin: 10px;
	max-width: 1000px;
	margin: auto;
	align-items: center;
	@media (max-width: 768px) {
		flex-direction: column;
		width: 100%;
	}
`;

export const DivTitre = styled.div`
	max-width: 1000px;
	padding-left: 36px;
	margin: auto;
	position: relative;
`;

export const InstaStep = styled.div`
	font-size: 17px;
	flex-basis: 20%;
	padding-right: 24px;
`;

export const InstaSection = styled.div`
	font-size: 28px;
	font-family: EuclidFlexLight;
	transition: 1s;
`;

//Partie d'Adam

export const InstaTitleContainer = styled.div`
	display: flex;
	align-items: center;
	height: 50px;
`;

export const GreenPoint = styled.div`
	background-color: rgb(141, 198, 137);
	height: 8px;
	width: 8px;
	margin: 6px;
	border-radius: 12px;
`;

export const InstaImageTextContainer = styled.div`
	display: flex;
`;

//Fin de la partie d'Adam
export const InstaTexte = styled.p`
	animation: fadeIn linear 1.6s;
`;

export const InstaImage = styled.div`
	font-size: 17px;
	flex-basis: 30%;
	margin: 0px 24px;
	@media (max-width: 768px) {
		text-align: center;
		margin-bottom: 10px;
	}
`;

export const DivTexte = styled.div`
	flex-basis: 50%;
	padding: 10px 36px;
	height: 300px;
	min-width: 329px;

	@media (max-width: 770px) {
		min-height: 180px;
	}

	@media (max-width: 600px) {
		min-height: 200px;
	}

	@media (max-width: 500px) {
		min-height: 220px;
	}

	@media (max-width: 400px) {
		min-height: 240px;
	}
`;

export const InstagramSection = styled.div`
	margin: 100px;
	@media (max-width: 768px) {
		margin: 10px;
		margin-top: 50px;
		margin-bottom: 50px;
	}
`;

export const DivImgStep = styled.div``;

export const Circle = styled.div`
	width: 70px;
	margin: 10px;
	margin-top: 24px;
	cursor: pointer;
	@media (max-width: 768px) {
		width: 70px;
		margin: 20px;
	}
`;

export const StepWrapper = styled.div``;

export const InstaSTitre = styled.h3`
	font-size: 1.6em;
	font-family: 'EuclidFlexUltraLight';
	margin-bottom: 10%;
`;

export const InstaTitre = styled.h2`
	font-size: 2em;
	font-weight: medium;
	font-family: 'Euclid Flex Medium';
	src: url('../src/Fonts/EuclidFlex-Medium.otf');
	line-height: 0;
`;

export const ComingText = styled.h2`
	font-weight: medium;
	font-family: 'Euclid Flex Medium';
	src: url('../src/Fonts/EuclidFlex-Medium.otf');
	line-height: 0;
	color: #96c08d;
	font-size: 28px;
	text-align: center;
`;
