import { useState } from 'react';
import StepNavigation from './StepNavigation.js';
import {
	DivTexte,
	InstaStep,
	InstaSection,
	InstaTexte,
	InstaImage,
	DivInsta,
	DivTitre,
	InstagramSection,
	InstaTitleContainer,
	GreenPoint,
} from '../Styles/Instagram';
import { InstaSTitre, InstaTitre } from '../Styles/Instagram';
import { widthImgInsta, altImgInsta } from '../i18n/Images/Instagram.tsx';
import { useScrollPercentage } from 'react-scroll-percentage';
import '../Assets/Style.css';

export function Instagram(texte, titre, image, h1, h2, id) {
	const labelArray = ['0', '1', '2'];

	const [currentTexte, updateCurrentTexte] = useState(texte[0]);
	const [currentTitre, updateCurrentTitre] = useState(titre[0]);
	const [currentImage, updateCurrentImage] = useState(image[0]);
	const [disableInstaTexte, setDisableInstaTexte] = useState(false);

	const [ref1, percentage1] = useScrollPercentage();

	function updateTexte(step) {
		if (currentTexte !== texte[step]) {
			setDisableInstaTexte(true);
			setTimeout(() => {
				setDisableInstaTexte(false);
			}, 1);
		}
		return (
			<div>
				{updateCurrentTexte(texte[step])}
				{updateCurrentTitre(titre[step])}
				{updateCurrentImage(image[step])}
			</div>
		);
	}

	const setOpacity = () => {
		if (percentage1 <= 0.3) {
			return percentage1 * 3.33;
		} else if (percentage1 > 0.3 && percentage1 < 0.7) {
			return 1;
		} else {
			return percentage1 * -3.33 + 3.33;
		}
	};

	return (
		<InstagramSection ref={ref1} style={{ opacity: setOpacity() }}>
			<DivTitre>
				<InstaTitre>{h1}</InstaTitre>
				<InstaSTitre>{h2}</InstaSTitre>
			</DivTitre>
			<DivInsta>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<InstaStep>
						<StepNavigation
							labelArray={labelArray}
							currentTexte={currentTexte}
							updateTexte={updateTexte}
							currentTitre={currentTitre}
							currentImage={currentImage}
							id={id}></StepNavigation>
					</InstaStep>
					<InstaImage>
						<img
							src={currentImage}
							width={widthImgInsta}
							alt={altImgInsta}></img>
					</InstaImage>
				</div>
				<DivTexte>
					<InstaTitleContainer>
						<InstaSection id={'ASK' + id}>Ask</InstaSection>
						<GreenPoint />
						<InstaSection id={'ANALYSE' + id}>Analyse</InstaSection>
						<GreenPoint />
						<InstaSection id={'ANSWER' + id}>Answer</InstaSection>
					</InstaTitleContainer>
					<InstaTexte
						style={{
							display: disableInstaTexte ? 'none' : 'inherit',
						}}>
						{currentTexte}
					</InstaTexte>
				</DivTexte>
			</DivInsta>
		</InstagramSection>
	);
}

export default Instagram;
