import { One, Two } from '../Styles/Colomn'
import { Box, GreenRound } from "../Styles/Global"
import { ThreeParagraphs } from './ThreeParagraphsView'
import { RondImage } from './RondImageView'
import '../Assets/Style.css'
import { useScrollPercentage } from 'react-scroll-percentage'
import { useEffect } from 'react'


const Resume = (titre: string, sousTitre: string, paragraphe: string, img: any, alt: any, width: number, alternance: boolean, id: number) => {
    // const [isbeginning, setIsBeginning] = useState(true)

    const [ref1, percentage1] = useScrollPercentage({
        /* Optional options */
        threshold: 0.2,
    })

    const [ref2, percentage2] = useScrollPercentage({
        /* Optional options */
        threshold: 0.2,
    })

    const [ref3, percentage3] = useScrollPercentage({
        /* Optional options */
        threshold: 0.2,
    })

    const setLeftOrRight = (id: number) => {
        if (id === 1) {
            if (percentage1 <= 0.2) {
                return percentage1 * 100
            }
            else if (percentage1 > 0.90) {
                return (Math.sqrt(percentage1 * 100 - 90)) * -1
            }
            else {
                return 0
            }
        }

        if (id === 2) {
            if (percentage2 === 0 || percentage1 === 1) {
                return 0
            }
            else if (percentage2 > 0.80) {
                return (Math.sqrt(percentage2 * 100 - 80))
            }
        }

        if (id === 3) {
            // if (percentage3 <= 0.2) {
            //     return (percentage3 * 25 - 5) *-1
            // }
            // else if (percentage3 > 0.80) {
            //     return (Math.sqrt(percentage3 * 100 - 80)) * -1
            // }
            // else {
            //     return 0
            // }
            if (percentage3 === 0 || percentage1 === 1) {
                return 0
            }
            else if (percentage3 > 0.80) {
                return (Math.sqrt(percentage3 * 100 - 80)) * -1
            }
        }

    }

    const setOpacity = (id: number) => {
        if (id === 1) {
            if (percentage1 <= 0.1){
                return percentage1 * 10
            } else if(percentage1 > 0.2 && percentage1 < 0.8){
                return 1
            } else {
                return percentage1 * (-5) + 5
            }
            // if (percentage1 === 0) {
            //     return 1
            // }
            // else if (percentage1 > 0.75) {
            //     return Math.pow(1 - percentage1 + 0.75, 6)
            // }
        }

        if (id === 2) {
            if (percentage2 <= 0.1){
                return percentage2 * 10
            } else if(percentage2 > 0.2 && percentage2 < 0.8){
                return 1
            } else {
                return percentage2 * (-5) + 5
            }
        }

        if (id === 3) {
            if(percentage3 > 0.2 && percentage3 < 0.8){
                return 1
            } else {
                return percentage3 * (-5) + 5
            }
        }
    }

    useEffect(() => {
        document.getElementById('1')!.style.opacity = "0"
        document.getElementById('2')!.style.opacity = "0"
        document.getElementById('3')!.style.opacity = "0"

        setTimeout(() => {
            // setIsBeginning(false)
            document.getElementById("1")!.style.animationFillMode = 'none'
            document.getElementById('1')!.style.opacity = "1"

            document.getElementById("2")!.style.animationFillMode = 'none'
            document.getElementById('2')!.style.opacity = "1"

            document.getElementById("3")!.style.animationFillMode = 'none'
            document.getElementById('3')!.style.opacity = "1"

        }, 1000);
    }, [])

    if (alternance === true) {
        return (
            <Box id={id.toString()} ref={id === 1 ? ref1 : ref3} idBox={id} style={{ display: 'flex', right: setLeftOrRight(id) + '%', opacity: setOpacity(id)}}>
                <One>{ThreeParagraphs(titre, sousTitre, paragraphe)}</One>
                <Two style={{ display: "flex", alignItems: "flex-end", zIndex: 2 }}>{RondImage(img, alt, width)}</Two>
            </Box >)
    }

    else {
        return (
            <Box id={id.toString()} ref={ref2} idBox={id} style={{ display: 'flex', right: setLeftOrRight(id) + '%', opacity: setOpacity(id)}}>
                <Two style={{zIndex: 2}}>{RondImage(img, alt, width)}</Two>
                <One style={{ textAlign: "end" }}>
                    <GreenRound />
                    {ThreeParagraphs(titre, sousTitre, paragraphe)}</One>
            </Box>
        )
    }
}

export default Resume