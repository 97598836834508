import { Parallax } from "react-parallax"
import { DivTransitionImage } from "../Styles/TransitionImage"

export function TransitionImage(img: any, alt: any) {
    return (
        <Parallax bgImage={img} strength={100}>
            <DivTransitionImage/>
        </Parallax>

    )
}

export default TransitionImage