import styled from "styled-components"
import DemiCercle from '../Assets/Background/DemiCercleReverse.svg'
import GreenCircle from '../Assets/Background/Round.svg'

export const Box = styled.div<{idBox:number}>`
display: flex;
flex-wrap: wrap;
flex-direction: row;
padding: 10px;
position: relative;
max-width: 1000px;
margin: auto;
animation: 1s ${props => props.idBox === 2 ? "slideInFromRight" : "slideInFromLeft"} linear ${props => props.idBox === 2 ? "300ms" : props.idBox === 3 ? "600ms" : "0s"} forwards;

@media (max-width: 768px) {
    flex-direction: column;
    margin-left: 24px;
    margin-right: 24px;
    bottom: ${props => (props.idBox === 3 || props.idBox === 2) && 60 + 'px'};
  }
  @media (min-width: 768px) {
    margin-top:3%;
  }
`;

export const BoxBlue = styled.div`
display: flex;
flex-wrap: wrap;
flex-direction: row;
padding: 10px;
@media (max-width: 768px) {
    flex-direction: column;
  }
  @media (min-width: 768px) {
  }
  background:#dbecda;
`;

export const Carrousel = styled.div`
  max-width: 1000px;
  width: 100%;
  height: 150px;

  @media (max-width: 968px) {
    align-items: flex-end;
    width: 80%;
    height: 110px;
  }

`;

export const BackgroundArcCircle = styled.div`
  background-image: url(${DemiCercle});
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 300px;
  width: 100%;
  max-width: 1200px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;

  @media (max-width: 768px) {
   height: 200px;
  }
`

export const Titre = styled.h1`
font-size: 2em;
  font-weight : medium;
  font-family: "Euclid Flex Medium";
	src: url('../src/Fonts/EuclidFlex-Medium.otf');
  line-height : 0;
`;

export const STitre = styled.h2`
font-size: 1.6em;
font-family: "EuclidFlexUltraLight";
margin: 8px 0px;
`;

export const Texte = styled.p`
font-size: 1em;
margin-left : 10%;
font-family: "Euclid Flex Medium";
src: url('../src/Fonts/EuclidFlex-Medium.otf');
`;

export const GreenRound = styled.div`
  background-image: url(${GreenCircle});
  width: 700px;
  height: 700px;
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  right: 0px;
  z-index: 1;

  @media (max-width: 968px) {
    opacity: 0;
  }
`

export const CarouselContainer = styled.div`
  margin-top: 40px;
  position: relative;

  @media (max-width: 768px) {
    bottom: 60px;
  }  
`

