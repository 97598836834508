import styled from "styled-components"

export const DivBulle = styled.div`
overflow:hidden;
-webkit-border-radius:200px;
-moz-border-radius:200px;
border-radius:200px;
width:400px;
height:400px;
background:white;
display: block;
margin-left: auto;
margin-right: auto;
margin-top: auto;
margin-bottom : auto;
text-align:center;
`;

export const DivRondVert = styled.div`
overflow:hidden;

width:90%;
height:90%;
background:white;
background: #dbecda;
display: block;
margin-left: auto;
margin-right: auto;
margin-top: auto;
margin-bottom : auto;
padding:25px;
margin-top:25px;
margin-bottom:25px;
`;

export const BulleContent = styled.div`
position: relative;
top: 25%;
`;