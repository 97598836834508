import imgTransitionSkin from "../../Assets/NosSolutions/Banner1.jpg"
import imgTransitionBeauty from "../../Assets/NosSolutions/Banner2.jpg"
import imgTransitionStyle from "../../Assets/NosSolutions/Banner3.jpg"

export const TransitionSkin = imgTransitionSkin
export const altTransitionSkin = "Image Skin HD"

export const TransitionBeauty = imgTransitionBeauty
export const altTransitionBeauty = "Image Beauty F D"

export const TransitionStyle = imgTransitionStyle
export const altTransitionStyle = "Image Style F D"
