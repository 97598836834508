import { Colomn1, Colomn2 } from '../Styles/Colomn'
import { Box } from "../Styles/Global"
import { ParagraphsAbout } from './ParagraphsAboutView'
import { RondImageAbout } from './RondImageAboutView'


export const ResumeTeam = (titre: string, sousTitre: string, paragraphe: string, img: any, alt: any, width: number, alternance: boolean) => {
    if (alternance === true) {

        return (
            <Box idBox={10}>
                <Colomn1>{RondImageAbout(img, alt, width, alternance)}</Colomn1>
                <Colomn2> {ParagraphsAbout(titre, sousTitre, paragraphe)}</Colomn2>
            </Box>
        )
    }
    else {
        return (
            <Box idBox={10}>
                <Colomn2> {ParagraphsAbout(titre, sousTitre, paragraphe)}</Colomn2>
                <Colomn1>{RondImageAbout(img, alt, width, alternance)}</Colomn1>

            </Box>
        )
    }
}
export default ResumeTeam