import { useTranslation } from "react-i18next";

import { CarouselMarque } from "./GlobalFunction"
import { LinkContact } from "../Styles/NavBar";

import Resume from '../Components/Resume'
import TransitionImage from "../Components/TransitionImageView"
import { Instagram } from "../Components/Instagram.js"
import { imageSkin, imageAnalyse } from "../i18n/Images/Instagram"
import { TransitionSkin, altTransitionSkin, TransitionBeauty, altTransitionBeauty, TransitionStyle, altTransitionStyle } from '../i18n/Images/TransitionData'
import { imgStyle, altStyle, imgBeauty, altBeauty, altSkin, imgSkin, positionSkin, positionBeauty, positionStyle, widthSkin, widthBeauty, widthStyle } from '../i18n/Images/SolutionsData'
import { DivTitre, InstagramSection, ComingText, InstaSTitre, InstaTitre } from "../Styles/Instagram"
import { BackgroundArcCircle, CarouselContainer } from "../Styles/Global";
import { useScrollPercentage } from 'react-scroll-percentage'

function NosSolutions() {
    return (
        <div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
                <svg style={{ maxWidth: '1000px', width: '100%' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 865.52 210.68">
                    <defs>
                        <style>.cls-1</style>
                    </defs>
                    <title>Fichier 1</title>
                    <g id="Calque_2" data-name="Calque 2">
                        <g id="Calque_1-2" data-name="Calque 1">
                            <path fill='#daebd9' className="cls-1" d="M0,0H865.52c-.12,1,.06,2-.34,2.49-3,3.88-6.06,7.78-9.32,11.47q-9.69,11-19.64,21.68c-6.2,6.69-12.37,13.42-18.9,19.79-5.77,5.65-12,10.84-18.06,16.2-5.43,4.8-10.83,9.66-16.39,14.31-4.28,3.57-8.74,6.93-13.21,10.25-8.67,6.44-17.2,13.1-26.17,19.08-10.74,7.16-21.75,13.92-32.88,20.44-10.7,6.26-21.5,12.44-32.64,17.85-15.53,7.53-31.31,14.52-47.56,20.52-10.37,3.83-20.9,7.13-31.27,10.91-12.21,4.46-24.89,7.19-37.36,10.68-7.17,2-14.5,3.47-21.82,4.92-4.94,1-10,1.43-15,2.22-5.29.84-10.56,1.85-15.84,2.72-2.9.47-5.8.88-8.72,1.21-3.74.41-7.5.77-11.26,1-7.36.51-14.75.62-22,2a9.36,9.36,0,0,1-1.5,0c-13.59.34-27.19,1.18-40.75.81-8.08-.22-16.22-.3-24.26-1-4.09-.35-8.19-.48-12.27-.84-5-.45-10-1.34-15-1.9-4.08-.45-8.18-.67-12.26-1.06-3.26-.31-6.52-.61-9.75-1.12s-6.34-1.48-9.55-1.82c-10.7-1.15-21.08-3.86-31.53-6.22-9.15-2.07-18.25-4.42-27.32-6.87-5.16-1.4-10.18-3.3-15.29-4.89-6.81-2.12-13.75-3.89-20.45-6.31-8.25-3-16.32-6.47-24.47-9.73-5.13-2.06-10.37-3.88-15.39-6.18-6.23-2.85-12.25-6.16-18.43-9.14-16.8-8.1-32.83-17.53-48.69-27.33-14.36-8.87-28.12-18.52-41.52-28.74-9.28-7.08-18.16-14.65-27.17-22C55.24,62.07,41.14,46.59,26.32,31.84,20.39,26,15.37,19.13,10,12.71,6.7,8.81,3.49,4.85.27.9A2.57,2.57,0,0,1,0,0Z" />
                        </g>
                    </g>
                </svg>
            </div>
            {/* DEGUEUX - MIEUX FAIRE */}
            <div style={{ width: '100%', margin:"auto", marginTop:"3em", marginBottom:"3em"}}>
                <div style={{margin: "0 auto", width:"148px"}}>
                    <LinkContact href="mailto:contact@imaaagery.com" >CONTACTEZ-NOUS</LinkContact> 
                </div>
            </div>
            <ResumeSolutions />
            <PresentationSolutions />
             {/* DEGUEUX - MIEUX FAIRE */}
             <div style={{ width: '100%', margin:"auto", marginTop:"3em", marginBottom:"3em"}}>
                <div style={{margin: "0 auto", width:"148px"}}>
                    <LinkContact href="mailto:contact@imaaagery.com" >CONTACTEZ-NOUS</LinkContact> 
                </div>
            </div>
        </div>
    )
}

function ResumeSolutions() {
    const { t } = useTranslation();

    return (
        <div>
            {Resume(t('solutions.titreSkin'), t('solutions.sousTitreSkin'), t('solutions.paragrapheSkin'), imgSkin, altSkin, widthSkin, positionSkin, 1)}
            {Resume(t('solutions.titreBeauty'), t('solutions.sousTitreBeauty'), t('solutions.paragrapheBeauty'), imgBeauty, altBeauty, widthBeauty, positionBeauty, 2)}
            {Resume(t('solutions.titreStyle'), t('solutions.sousTitreStyle'), "Coming Soon...", imgStyle, altStyle, widthStyle, positionStyle, 3)}
        </div>
    )
}

function ComingSoon(h1: string, h2: string) {
    const [ref, percentage] = useScrollPercentage()

    const setOpacity = () => {
        if (percentage <= 0.3){
            return percentage * 3.33
        } else if(percentage > 0.3 && percentage < 0.7){
            return 1
        } else {
            return percentage * (-3.33) + 3.33
        }
    }

    return (
            <InstagramSection style={{opacity: setOpacity()}} ref={ref}>
                <DivTitre>
                    <InstaTitre>{h1}</InstaTitre>
                    <InstaSTitre>{h2}</InstaSTitre>
                </DivTitre>
                <ComingText style={{marginBottom: "50px"}}>Coming Soon...</ComingText>
            </InstagramSection>
    )
}

function PresentationSolutions() {
    const { t } = useTranslation();

    return (
        <CarouselContainer>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <BackgroundArcCircle>
                    <CarouselMarque />
                </BackgroundArcCircle>
            </div>

            {TransitionImage(TransitionSkin, altTransitionSkin)}
            {Instagram([t("instagram.texteSkin.0"), t("instagram.texteSkin.1"), t("instagram.texteSkin.2")], [t("instagram.titreSkin.0"), t("instagram.titreSkin.1"), t("instagram.titreSkin.2")], imageSkin, t("solutions.titreSkin"), t("solutions.sousTitreSkin"), "1")}
            {TransitionImage(TransitionBeauty, altTransitionBeauty)}
            {Instagram([t("instagram.texteBeauty.0"), t("instagram.texteBeauty.1"), t("instagram.texteBeauty.2")], [t("instagram.titreBeauty.0"), t("instagram.titreBeauty.1"), t("instagram.titreBeauty.2")], imageAnalyse, t("solutions.titreBeauty"), t("solutions.sousTitreBeauty"), "2")}
            {TransitionImage(TransitionStyle, altTransitionStyle)}
            {ComingSoon(t("solutions.titreStyle"), t("solutions.sousTitreStyle"))}
        </CarouselContainer>
    )
}

export default NosSolutions