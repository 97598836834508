import React, {useState, useEffect} from 'react';

const CircularProgressBar = (props) =>{
    const size = props.size;
    const radius = (props.size - props.strokeWidth) / 2;
    const viewBox = `0 0 ${props.size} ${props.size}`;
    const [dashArray, setDashArray] = useState(radius * Math.PI * 2);
    const [dashOffset, setDashOffset] = useState(dashArray - dashArray * props.percentage/100);

  useEffect(() => {
    setDashArray(radius * Math.PI * 2);
  }, [radius])

  useEffect(() => {
    setDashOffset(dashArray - dashArray * props.percentage/100);
  }, [dashArray, props.percentage])

    return(
        <svg
          width={size}
          height={size}
          viewBox={viewBox}
        >
          <circle
            style={{stroke: '#fff', fill: '#deebda'}}
            cx={size / 2}
            cy={size / 2}
            r={radius}
            strokeWidth={`${props.strokeWidth}px`} />
          <circle
            style={{fill: 'none', stroke: '#96c08d', strokeLinecap: 'round', strokeLinejoin: 'round', strokeDasharray: dashArray, strokeDashoffset: dashOffset}}
            cx={size / 2}
            cy={size / 2}
            r={radius}
            strokeWidth={`${props.strokeWidth}px`}
            // Start progress marker at 12 O'Clock
            transform={`rotate(-90 ${size / 2} ${size / 2})`}
          />
          <text
            style={{fontSize: '40px', fill: '#fff'}}
            x="50%"
            y="50%"
            dy=".3em"
            textAnchor="middle">
            A
          </text>
      </svg>
    )
}

export default CircularProgressBar;