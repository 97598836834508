import { createGlobalStyle } from 'styled-components'
import EuclidLight from '../Fonts/EuclidFlex-Light.otf'
import EuclidUltraLight from '../Fonts/EuclidFlex-Ultralight.otf'

export const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'EuclidFlexLight';
        src: url(${EuclidLight}) format("opentype");
    }

    @font-face {
        font-family: 'EuclidFlexUltraLight';
        src: url(${EuclidUltraLight}) format("opentype");
    }

    body {
      }
`;
