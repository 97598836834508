import SkinAsk from "../../Assets/NosSolutions/skinHDOne.png"
import SkinAsk2 from "../../Assets/NosSolutions/skinHDTwo.png"
import SkinAsk3 from "../../Assets/NosSolutions/skinHDThree.png"

import BeautyFD from "../../Assets/NosSolutions/BeautyFDOne.png"
import BeautyFD2 from "../../Assets/NosSolutions/BeautyFDTwo.png"
import BeautyFD3 from "../../Assets/NosSolutions/BeautyFDThree.png"

export const imageSkin = [SkinAsk, SkinAsk2, SkinAsk3]
export const imageAnalyse = [BeautyFD, BeautyFD2, BeautyFD3]
export const imageStyle = [SkinAsk, SkinAsk, SkinAsk]

export const widthImgInsta = 200
export const altImgInsta = "Illustration"