import { DivPortrait, Titre, SousTitre, Texte } from "../Styles/ParagraphsAbout"

export function ParagraphsAbout(titre: string, sousTitre: String, paragraph: string) {
    return (
        <DivPortrait>
            <Titre>{titre}</Titre>
            <SousTitre>{sousTitre}</SousTitre>
            <Texte>{paragraph}</Texte>
        </DivPortrait>
    )
}

export default ParagraphsAbout