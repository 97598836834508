import styled from "styled-components"

export const DivImage = styled.div`
overflow:hidden;
-webkit-border-radius:200px;
-moz-border-radius:200px;
border-radius:200px;
width:300px;
height:300px;
z-index: 5;

display: block;
margin-left: auto;
margin-right: auto;
margin-top: auto;
margin-bottom : auto;
display: flex;

@media (max-width: 769px) {
    overflow:hidden;
    -webkit-border-radius:100px;
    -moz-border-radius:100px;
    border-radius:150px;
    width:140px;
    height:140px;
    margin: 0;
}
`;