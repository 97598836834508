import { useTranslation } from "react-i18next";
import Carousel from "react-multi-carousel";

import { CarouselMarque } from "./GlobalFunction"

import { DivBulle, DivRondVert } from "../Styles/Bulle"

import ResumeTeam from "../Components/ResumeTeam"
import Bulle from "../Components/Bulle"

import { portraitAlain, altPortraitAlain, widthPortraitAlain, portraitPartenaire, altPortraitPartenaire, widthPortraitPartenaire, portraitKarine, altPortraitKarine, widthPortraitKarine, widthPortraitLeo, altPortraitLeo, portraitLeo } from "../i18n/Images/PresentationData"

function About() {
    return (
        <div>
            <ResumePresentationFondateur />
            <CarouselBulle />
            <ResumePresentationPartenaire />
            <CarouselMarque />
        </div>
    );
}

function ResumePresentationFondateur() {
    const { t } = useTranslation();
    return (
        <div>
            {ResumeTeam(t('fondateur.titre'), t('fondateur.sousTitre'), t('fondateur.paragraphe'), portraitAlain, altPortraitAlain, widthPortraitAlain, true)}
            {ResumeTeam(t('karine.titre'), t('karine.sousTitre'), t('karine.paragraphe'), portraitKarine, altPortraitKarine, widthPortraitKarine, false)}
            {ResumeTeam(t('leo.titre'), t('leo.sousTitre'), t('leo.paragraphe'), portraitLeo, altPortraitLeo, widthPortraitLeo, false)}
        </div>
    )
}
export function ResumePresentationPartenaire() {
    const { t } = useTranslation();
    return (
        <div>
            {ResumeTeam(t('notrePartenaire.titre'), t('notrePartenaire.sousTitre'), t('notrePartenaire.paragraphe'), portraitPartenaire, altPortraitPartenaire, widthPortraitPartenaire, true)}
        </div>
    )
}

export function CarouselBulle() {
    const { t } = useTranslation();

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
            partialVisibilityGutter: 100
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,

        }
    };

    return (
        <DivRondVert>
            <p>Expert en pilotage</p>
            <p>de projets de beauté digitale</p>
            <p>et experience client</p>
            <Carousel partialVisible={true} responsive={responsive}>
                <DivBulle>{Bulle(t('ask.titre'), t('ask.liste1'), t('ask.bouton'))}</DivBulle>
                <DivBulle>{Bulle(t('analyse.titre'), t('analyse.liste1'), t('analyse.bouton'))}</DivBulle>
                <DivBulle>{Bulle(t('answer.titre'), t('answer.liste1'), t('answer.bouton'))}</DivBulle>
            </Carousel>
        </DivRondVert>
    )
}

export default About