import styled from "styled-components"

export const DivPortrait = styled.div`
width:80%;
@media (max-width: 769px) {
    width:100%;
  }
`;
export const Titre = styled.h1`
font-size: 20px;
font-weight : normal;
color : #96c08d;
`;

export const SousTitre = styled.h2`
font-size: 15px;
`;

export const Texte = styled.p`
font-size: 15px;
`;