import Reference from "../Components/ReferenceView"
import Logo from "../Assets/Carousel/logoClinique.png"
import { useTranslation } from "react-i18next";

function Ref() {
    const { t } = useTranslation();

    return (
        <div>
            {Reference(Logo, 'logo', 200, t('clinique.bouton'), t('clinique.introduction'), t('clinique.liste1'), t('clinique.liste2'), t('clinique.liste3'), t('clinique.sousTitre'), t('clinique.paragraphe1'), t('clinique.paragraphe2'), t('clinique.paragraphe3'), t('clinique.paragraphe4'))}
        </div>
    );
}
export default Ref
