import { Titre, STitre, Texte } from "../Styles/Global"
import { DivTexte } from '../Styles/ThreeParagraphs'

export function ThreeParagraphs(titre: string, sousTitre: String, paragraph: string) {
    return (
        <DivTexte>
            <Titre>
                {titre}
            </Titre>
            <STitre>{sousTitre}</STitre>
            <Texte>{paragraph}</Texte>
        </DivTexte>
    )
}

export default ThreeParagraphs