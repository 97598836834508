import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import '../Assets/Carousel/style.css';
import { Carrousel } from '../Styles/Global';
import { Centrer } from '../Styles/NavBar';

import { altClinique, altMaryKay, Clinique, MaryKay, widthClinique, widthMaryKay,widthMyBlend,MyBlend,altMyBlend } from '../i18n/Images/Carousel';

export function CarouselMarque() {
	const responsive = {
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 2,
			slidesToSlide: 2,
		},
		tablet: {
			breakpoint: { max: 1024, min: 664 },
			items: 2,
			slidesToSlide: 2,
		},
		mobile: {
			breakpoint: { max: 664, min: 0 },
			items: 1,
			slidesToSlide: 1,
		},
	};

	return (
		<Carrousel>
			<Carousel containerClass='container' responsive={responsive}>
				{/* On change de position Clinique et Myblend suivant Mobile ou desktop pour mettre Clinique en avant */}
				 {window.innerWidth > 500 ? (
					<Centrer>
						<img style={{ objectFit: 'contain', width: window.innerWidth > 1100 ? widthMyBlend : widthMyBlend - 40 }} src={MyBlend} alt={altMyBlend}></img>
					</Centrer>
				) : ( 
					 <Centrer>
						<a href='https://www.clinique.com/clinicalreality' target='_blank' rel='noreferrer'>
							<img style={{ objectFit: 'contain', width: widthClinique - 20, position: 'relative', top: '12px' }} src={Clinique} alt={altClinique}></img>
						</a>
					</Centrer>
				)} 

				 {window.innerWidth > 500 ? (
					
					<Centrer>
						{' '}
						<a href='https://www.clinique.com/clinicalreality' target='_blank' rel='noreferrer'>
							<img style={{ objectFit: 'contain', width: window.innerWidth > 1100 ? widthClinique : widthClinique - 40, position: 'relative', top: '12px' }} src={Clinique} alt={altClinique}></img>
						</a>
					</Centrer>
				 ) : (
				 	<Centrer>
				 		<a href='https://my-blend.com' target='_blank' rel='noreferrer'>
				 			<img style={{ objectFit: 'contain', width: widthMyBlend - 20, height: '100%' }} src={MyBlend} alt={altMyBlend}></img>
				 		</a>
				 	</Centrer>
				 )}
				<Centrer>
					<a href='https://www.marykay.com/en-us/tips-and-trends/mobile-vmo-app#tabbed1' target='_blank' rel='noreferrer'>
						<img
							style={{ objectFit: 'contain', width: window.innerWidth > 500 ? window.innerWidth > 1100 ? widthMaryKay : widthMaryKay -40 : widthMaryKay - 20, position: 'relative', top: '5px' }}
							src={MaryKay}
							alt={altMaryKay}></img>
					</a>
				</Centrer>
			</Carousel>
		</Carrousel>
	);
}
