import styled from "styled-components"

export const One = styled.div`
flex-basis:45%;
flex-direction: row;
display: flex;
justify-content: center;
align-items: center;
@media (max-width: 768px) {
    flex-direction: column;
    flex-basis:100%;
  }
`;
export const Two = styled.div`
flex-basis:55%;
flex-direction: row;
@media (max-width: 768px) {
    flex-direction: column;
    flex-basis:100%;
  }
`;

export const Colomn1 = styled.div`
flex-basis:40%;
flex-direction: row;
@media (max-width: 768px) {
    flex-direction: column;
    flex-basis:100%;
  }
`;
export const Colomn2 = styled.div`
flex-basis:60%;
flex-direction: row;
@media (max-width: 768px) {
    flex-direction: column;
    flex-basis:100%;
  }
`;

export const Col25 = styled.div`
flex-basis:25%;
flex-direction: row;
@media (max-width: 768px) {
    flex-direction: column;
    flex-basis:100%;
  }
`;

export const Col50 = styled.div`
flex-basis:50%;
flex-direction: row;
@media (max-width: 768px) {
    flex-direction: column;
    flex-basis:100%;
  }
`;