import styled from "styled-components"

export const DivTransitionImage = styled.div`
overflow:hidden;
display: block;
margin-left: auto;
margin-right: auto;
margin-top: auto;
margin-bottom : auto;
width : 100%;
height : 600px;

@media (max-width: 969px) {
    overflow:hidden;
    width:100%;
    height:300px;
}
`;