import { Colomn1, Colomn2 } from '../Styles/Colomn'
import { Box } from "../Styles/Global"
import RondImageAbout from './RondImageAboutView'
import { PartOne, PartTwo, Container } from '../Styles/Reference'

export const Reference = (img: any, alt: string, width: number, bouton: string, introduction: string, liste1: string, liste2: string, liste3: string, sousTitre: string, paragraphe1: string, paragraphe2: string, paragraphe3: string, paragraphe4: string) => {
        return (
                <Box idBox={4}>
                        <Colomn1>
                                {RondImageAbout(img, alt, width, true)}
                                <button>{bouton}</button>
                        </Colomn1>
                        <Colomn2>
                                <Container>
                                        <PartOne>
                                                <div>
                                                        <p>{introduction}</p>
                                                        <ul>
                                                                <li>{liste1}</li>
                                                                <li>{liste2}</li>
                                                                <li>{liste3}</li>
                                                        </ul>
                                                        <p>{sousTitre}</p>
                                                        <p>{paragraphe1}</p>
                                                        <p>{paragraphe2}</p>
                                                        <p>{paragraphe3}</p>
                                                        <p>{paragraphe4}</p>
                                                </div>
                                        </PartOne>
                                        <PartTwo>a
                                        </PartTwo>
                                </Container>
                        </Colomn2>
                </Box>
        )
}

export default Reference