import styled from "styled-components"

export const ImageCircle = styled.div`
overflow:hidden;
-webkit-border-radius:200px;
-moz-border-radius:200px;
border-radius:200px;
width:250px;
height:250px;
border: 5px solid #96c08d;
display: block;
text-align: center;
margin: auto;
`;

export const Image = styled.div`
overflow:hidden;
-webkit-border-radius:200px;
-moz-border-radius:200px;
border-radius:200px;
width:250px;
height:250px;
display: block;

text-align: center;
margin: auto;

`;