import logoClinique from "../../Assets/Carousel/logoClinique.png"
import logoMyBlend from "../../Assets/Carousel/logoMyBlend.svg"
import logoMaryKay from "../../Assets/Carousel/logoMaryKay.png"

export const Clinique = logoClinique
export const altClinique = "Logo Clinique"
export const widthClinique = 200

export const MyBlend = logoMyBlend
export const altMyBlend = "Logo MyBlend"
export const widthMyBlend = 200

export const MaryKay = logoMaryKay
export const altMaryKay = "Logo Mary Kay"
export const widthMaryKay = 240
