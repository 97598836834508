import imgBeautyHD from '../../Assets/NosSolutions/ImageRound3.jpg'
import imgSkinHD from '../../Assets/NosSolutions/ImageRound2.jpg'
import imgStyleHD from '../../Assets/NosSolutions/ImageRound1.png'

export const imgSkin = imgSkinHD
export const altSkin = "Image Skin HD"
export const widthSkin = 300
export const imgBeauty = imgBeautyHD
export const altBeauty = "Image Beauty HD"
export const widthBeauty = 300
export const imgStyle = imgStyleHD
export const altStyle = "Image Beauty HD"
export const widthStyle = 300

export const positionSkin: boolean = true
export const positionBeauty: boolean = false
export const positionStyle: boolean = true