import { ImageCircle, Image } from '../Styles/RondImageAbout'

export function RondImageAbout(img: any, alt: any, width: number, alternance: boolean) {
    if (alternance === true) {

        return (
            <ImageCircle>
                <img src={img} alt={alt} width={width}></img>
            </ImageCircle>
        )
    }
    else {
        return (
            <Image>
                <img src={img} alt={alt} width={width}></img>
            </Image>
        )
    }
}

export default RondImageAbout