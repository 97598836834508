import styled from "styled-components"
import { Link } from 'react-router-dom'
import { logoImAAAgery } from "../i18n/Images/LogoImaaagery";

export const Nav = styled.nav`
display: flex;
justify-content: center;
align-items: center;
position: fixed;
background-color: white;
z-index:1;
@media (min-width: 769px) {
    top:0;
    font-size:15px;
  }
@media (max-width: 768px) {
    bottom:0;
    font-size:0px;
  }
`;

export const NavUl = styled.ul`
width: 100%;
display: table;
table-layout: fixed; 
margin:10px;
padding:0;
`;

export const NavLi = styled.li`
display: table-cell;
width: auto;
text-align: center;

`;


export const StyledLink = styled(Link)`
    text-decoration: none;
    display: flex;
    flex-direction: column;
    color:black;

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
    `;

export const Centrer = styled.div`
  justify-content: center;
  text-align: center;
  margin: auto;
  display: flex;
  height: 100%;
`;

export const LinkContact = styled.a`
text-decoration:none;
color:white;
font-size:0.8em;
font-family: "Euclid Flex Medium";
min-width: 75px;
text-align: center;
background-color:#479379;
border-radius:8px;
padding:8px;

`;


export const HeaderSection = styled.div`
display: flex;
margin: auto;
align-items: center;
padding: 10px;
max-width: 800px;
justify-content: space-evenly;
z-index: 4;
background-color: none;
`;

//Partie d'Adam --------------------------------------------------------------------------------------

export const IMG = styled.div`
    border-image: none;
    background-size: contain;
    background-position-y: center;
    background-repeat: no-repeat; 
`;


export const ImageLogo = styled(IMG)`
    background-image: url(${logoImAAAgery});
    width: 200px;
    height: 50px;

    @media (max-width: 769px) {
      width: 170px;
      height: 40px;
    }
`

export const NavBar = styled.div`
    width: 100%;
    position: fixed;
    z-index: 5;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    transition: all 0.4s;
`