import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import NosSolutions from "./Pages/NosSolutions";
import About from "./Pages/About"
import Ref from "./Pages/References"
import Mentions from "./Pages/MentionsLegales"
import Footer from "./Components/Footer"
//import NavBar from "./Components/NavBar"
import Header from './Components/Header'
import 'intersection-observer'

export default function App() {
  return (
    <Router>
      {Header('mailto:contact@imaaagery.com')}
      {/*<NavBar />*/}
      <Switch>
        <Route path="/who">
          <Who />
        </Route>
        <Route path="/references">
          <References />
        </Route>
        <Route path="/MentionsLegales">
          <MentionsLegales />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
      {Footer('mailto:contact@imaaagery.com')}
    </Router>
  );
}

function Home() {
  return <div>
    <NosSolutions />
  </div>;
}

function Who() {
  return <div>
    <About />
  </div>;
}

function References() {
  return <div>
    <Ref />
  </div>;
}

function MentionsLegales() {
  return (
    <div>
      <Mentions />
    </div>
  )
}