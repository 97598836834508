import styled from "styled-components"

export const Container = styled.div`
display: flex;
align-items: center;
justify-content: center;
@media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const PartOne = styled.div`
width: 50%;
height: 600px;
border: 5px solid #96c08d;
border-radius: 20px;
background: white;
align-items: center;
display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    width: 100%;
    height: 400px;
  }
  position: relative;
`;


export const PartTwo = styled.div`
width: 50%;
height: 600px;
border: 5px solid #96c08d;
display: flex;
align-items: center;
justify-content: center;
border-radius: 20px;
background: white;
margin-left: -50px;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
    margin-top: -50px;
    height:400px;
  }
  `;