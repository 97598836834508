import { BulleContent } from "../Styles/Bulle"

export function Bulle(titre: String, liste: String, lien: String) {
    return (
        <BulleContent>
            <h1>{titre}</h1>
            <ul>
                <li>
                    {liste}
                </li>
            </ul>
            <p>{lien}</p>
        </BulleContent>
    )
}
export default Bulle